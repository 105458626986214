<template>
    <div class="staff">
        <div class="banner">
            <img src="../assets/PC/staff_img_banner.png" alt="">
            <div class="text" :style="textLeft">
                <h2>链合人才  激发活力</h2>
                <p>链合优质人才，激发企业与个人活力共同成长</p>
            </div>
        </div>
        <div class="bannerH5">
            <h3>链合人才  激发活力</h3>
            <h5>链合优质人才，激发企业与个人活力共同成长</h5>
        </div> 
        <div class="personnel">
            <div class="main">
                 <div class="card" id="staff1">
                        <img src="../assets/PC/staff1.png"  alt="">
                        <div class="card_con">
                            <div class="tit">人才发展</div>
                            <p>T型人才是依蓝云最宝贵的财富</p>
                            <div class="box">
                                我们非常注重员工的发展，在企业中每个人不断学习进步，培养人才“纵向”有专业技能、“横向”有商业思维或管理思维的属性，不断提升眼界和格局
                            </div>
                        </div>
                 </div>
                 <div class="card" id="staff2">
                        <img src="../assets/PC/staff2.png"  alt="">
                        <div class="card_con">
                            <div class="tit">依蓝云学院</div>
                            <p>为员工打造专属于自己的成长之路，不仅重视专业技能的提升，还注重对思维和格局的培养</p>
                            <div class="box">
                                通过设计职业发展规划，以及对专业技能的不断提升，使其成为依蓝云T型人才和团队核心，为依蓝云的现在和未来培养优秀的人才
                            </div>
                        </div>
                 </div>
            </div>
        </div>
        <div class="personnelH5">
            <div class="main">
                 <div class="card">
                        <div class="tit">人才发展</div>
                        <p>T型人才是依蓝云最宝贵的财富</p>
                        <img src="../assets/PC/staff1.png" id="activityPC" alt="">
                        
                        <img src="../assets/H5/staff1.png" id="activityH5" alt="">
                        <div class="box">
                            我们非常注重员工的发展，在企业中每个人不断学习进步，培养人才“纵向”有专业技能、“横向”有商业思维或管理思维的属性，不断提升眼界和格局
                        </div>
                 </div>
                 <div class="card">
                        <div class="tit">依蓝云学院</div>
                        <p>为员工打造专属于自己的成长之路<br>不仅重视专业技能的提升，还注重对思维和格局的培养</p>
                        <img src="../assets/PC/staff2.png" id="activityPC" alt="">
                        
                        <img src="../assets/H5/staff2.png" id="activityH5" alt="">
                        <div class="box">
                            通过设计职业发展规划，以及对专业技能的不断提升，使其成为依蓝云T型人才和团队核心，为依蓝云的现在和未来培养优秀的人才
                        </div>
                 </div>
            </div>
        </div>
        <!-- 工作环境 -->
        <div class="environment" id="staff3">
            <div class="title">工作环境</div>
            <div class="main">
                <ul>
                    <li>
                        <img src="../assets/PC/environment1.png" alt="">
                        <div class="tit">办公环境</div>
                        <p>依蓝云总部位于青岛市高新技术产业区中关村信息谷，属于低密度办公区，共两层依蓝云所处1层区，拥有安静的工作环境。园区拥有咖啡吧、健身房、睡眠舱等配套设施，安静、简单的办公环境被员工称之为“青岛版Mini谷歌”</p>
                    </li>
                    <li style="float: right;">
                        <img src="../assets/PC/environment2.png" alt="">
                        <div class="tit">多元文化</div>
                        <p>依蓝云一直坚持公平、透明、坦诚、无歧视的办公氛围，对员工给予充分尊重，禁止同事间开具有恶意的玩笑。严格遵守国家有关法律，维护员工合法权益，共同建造一个，文明、公平、透明、愉悦的工作环境</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 员工活动 -->
        <div class="activity" id="staff4">
            <div class="title">员工活动</div>
            <p>趣味活动与企业文化融合，将文化、理念和信念持久传承</p>
            <ul>
                <li>
                    <img src="../assets/PC/activity1.png" class="img_bgc" id="activityPC" alt="">
                    <img src="../assets/H5/activity1.png" class="img_bgc" id="activityH5" alt="">
                    <div class="li_bgc"></div>
                    <div class="title">读书会活动</div>
                    <div class="active">
                        <div class="tit">读书会活动</div>
                        <p>读书会是一种在于拓展视野、宏观思维、知识交流的活动。为健全企业文化活动建设，拓展员工视野和提升员工文化素养，公司每日组织读书会活动</p>
                    </div>
                </li>
                <li>
                    <img src="../assets/PC/activity2.png" class="img_bgc" id="activityPC" alt="">
                    <img src="../assets/H5/activity2.png" class="img_bgc" id="activityH5" alt="">
                    <div class="li_bgc"></div>
                    <div class="title">员工大会</div>
                    <div class="active">
                        <div class="tit">员工大会</div>
                        <p>每年年底举行的员工大会，员工大会上，向员工传递公司战略、业务布局与管理思路，并表彰优秀员工和部门</p>
                    </div>
                </li>
                <li>
                    <img src="../assets/PC/activity3.png" class="img_bgc" id="activityPC" alt="">
                    <img src="../assets/H5/activity3.png" class="img_bgc" id="activityH5" alt="">
                    <div class="li_bgc"></div>
                    <div class="title">团建活动</div>
                    <div class="active">
                        <div class="tit">团建活动</div>
                        <p>组织员工进行户外运动活动，不仅强身健体、增进与同事之间的感情，并传递爱与社会责任</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            textLeft:'left:0px',
            scrollTopNumber:[121,511,921,1372]
        }
    },
    watch: {
        "$route": "fetchDate",
    },
    mounted () {
        
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:20px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:20px'
                }
            })();
        };
        let this_=this;
        setTimeout(function() {
                if(this_.$route.query.herfIndex){
                    this_.scrollTopNumber=[0,
                        document.querySelector('#staff2').offsetTop-70,
                        document.querySelector('#staff3').offsetTop-70,
                        document.querySelector('#staff4').offsetTop-70]
                    document.documentElement.scrollTop=this_.scrollTopNumber[this_.$route.query.herfIndex]
                    window.scrollTo(0, this_.scrollTopNumber[this_.$route.query.herfIndex])
                }
        }, 100)

    },
    methods: {
        fetchDate(){
            if(this.$route.query.herfIndex){
                document.documentElement.scrollTop=this.scrollTopNumber[this.$route.query.herfIndex]
                window.scrollTo(0, this.scrollTopNumber[this.$route.query.herfIndex])
            }
        },
    }
}
</script>
<style lang="less" scoped>
    .staff{
        padding-top: 70px;
        #activityPC{
            display: block;
            min-height: 240px;
        }
        #activityH5{
            display: none;
        }
        .bannerH5{
            display: none;
        }
        .banner{
            position: relative;
            width: 100%;
            img{
                width: 100%;height: 476px;
                // float: left;
            }
                .text{
                    position: absolute;
                    left: 12%;
                    top: 50%;
                    transform: translateY(-50%);
                    h2{
                        font-size: 64px;
                        color: #fff;
                        font-weight: normal;
                    }
                    p{
                        margin-top: 30px;
                        color: #fff;
                        font-size: 24px;
                    }
                }
        }
        .personnelH5{
            display: none;
        }
        .personnel{
            display: block;
            .card{
                width: 100%;
                height: 290px;
                padding-top: 100px;
                img{
                    width: 50%;
                    height: 290px;
                    float: right;
                }
                .card_con{
                    display: inline-block;
                    width: 50%;
                    .tit{
                        font-size: 36px;
                        padding-left: 23px;
                    }
                    p{
                        font-size: 16px;
                        padding-left: 23px;
                        height: 53px;
                        margin-top: 20px;
                    }
                    .box{
                        font-size: 16px;
                        height: 147px;
                        box-sizing: border-box;
                        padding: 38px 24px;
                        padding-right: 72px;
                        background-color: #ebf7ff;
                        margin-top: 16px;
                    }
                }
            }
            .card:nth-child(2){
                img{
                    float: left;
                }
                .card_con{
                    .tit{
                        padding-left: 72px;
                    }
                    p{ 
                        padding-left: 72px;
                        padding-right: 26px;
                    }
                    .box{
                        padding-left: 72px;
                        padding-right: 26px;
                    }
                }
            }
        }

        .environment{
            // margin-bottom: 120px;
            .title{
               font-size: 36px;
               text-align: center;
               padding-top: 120px;
               margin-bottom: 60px; 
            }
            ul{
                li{
                    width: 50%;
                    display: inline-block;
                    box-sizing: border-box;
                    padding-right: 20px;
                    img{
                        width: 100%;
                    }
                    .tit{
                        font-size: 24px;
                        margin-top: 32px;
                    }
                    p{
                        font-size: 16px;
                        margin-top: 20px;
                        text-align: justify;
                    }
                }
                li:nth-child(2){
                    padding-left: 20px;
                    padding-right: 0px;
                }
            }
        }
        // 员工活动
        .activity{
            padding-top: 120px;
            margin-bottom: 120px;
            .title{
                font-size: 36px;
                text-align: center;
            }
            p{
                text-align: center;
                margin: 23px auto 60px;
                font-size: 16px;
            }
            ul{
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    position: relative;
                    width: 33.3333%;
                    .img_bgc{
                        width: 100%;
                    }
                    .li_bgc{
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 156, 255, 0.5);
                    }
                    .title{
                        position: absolute;
                        z-index: 1;
                        left: 48px;
                        bottom: 48px;
                        font-size: 24px;
                        color: #fff;
                    }
                    .active{
                        display: none;
                        position: absolute;
                        top: 0;
                        z-index: 2;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        right: 0;
                        box-sizing: border-box;
                        padding:48px ;
                        color: #fff;
                        background-color: #009cff;
                        text-align: left;
                        .tit{
                            font-size: 24px;
                        }
                        p{
                            font-size: 16px;
                            margin-top: 30px;
                            text-align: left;
                        }
                    }
                }
                li:nth-child(2){
                    .li_bgc{
                        background-color: rgba(255, 168, 0, 0.5);
                    }
                    .active{
                        background-color:#ffa800 ;
                    }
                }
                li:nth-child(3){

                    .li_bgc{
                        background-color: rgba(134, 196, 0, 0.5);
                    }
                    .active{
                        background-color: #86c400;
                    }
                }
                li:hover{
                    .active{
                        display: block;
                    }
                }
            }
        }
    }

    // 手机端样式
    #app.H5Class {
        .staff{
            width: 7.5rem;
            // padding-bottom: 1rem;
            padding-top: 0.8rem;
            .activity{
                padding-top:0.9rem ;
                margin-bottom:0;
            }
            #activityPC{
                display: none;
            }
            #activityH5{
                display: block;
            }
            .banner{
                display: none;
            }
            .bannerH5{
                overflow: hidden;
                width: 100%;
                display: block;
                height: 5.6rem;
                background:url(../assets/H5/staff_img_banner.png) no-repeat;
                background-size: 100% 100%;
                h3{
                    margin-top: 1.2rem;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 400;
                    padding-left: 0.4rem;
                }
                h5{
                    margin-top: 0.3rem;
                    color: #fff;
                    padding-left: 0.4rem;
                    font-size: 0.24rem;
                    font-weight: 400;
                    // width: 4.3rem;
                }
            }
            .personnel{
                display: none;
            }
            .personnelH5{
                display: block;
                .card{
                    width: 100%;
                    img{
                        width: 100%;
                        height: 4rem;
                        float: none;
                    }
                    .tit{
                        font-size: 0.34rem;
                        text-align: center;
                        margin-top: 0.9rem;
                    }
                    p{
                        font-size: 0.24rem;
                        text-align: center;
                        width: 5.8rem;
                        margin: 0.3rem auto 0.4rem;
                    }
                    .box{
                        padding: 0.4rem;
                        font-size: 0.24rem;
                        background-color: #ebf7ff;
                    }
                }
            }

            .environment{
                padding: 0 0.4rem;
                .title{
                    font-size: 0.34rem;
                    padding-top: 1.2rem;
                    margin-bottom: 0.5rem;
                }
                ul{
                    border-bottom: 0.01rem solid #e6e6e6;
                }
                li{
                    width: 100%;
                    padding: 0;
                    margin-bottom: 0.9rem;
                    float: none!important;
                }
                .tit{
                    font-size: 0.34rem;
                }
                p{
                    font-size: 0.24rem;
                }
            }

            .activity{
                .title{
                    font-size: 0.34rem;
                }
                p{
                    font-size: 0.24rem;
                        margin: 0.3rem auto 0.4rem;
                }
                ul{
                    li{
                        height: 3.2rem;
                        width: 50%;
                        .title{
                            font-size: 0.24rem;
                            bottom: 0.4rem;
                            left: 0.4rem;
                        }
                        .img_bgc{
                            height: 100%;
                            
                        }
                        .li_bgc{
                        
                            background-color: transparent;
                        }
                        .active{
                            padding:0.4rem ;
                            .tit{
                                font-size: 00.34rem;
                            }
                            p{
                                font-size: 0.24rem;
                                margin-top: 0.2rem;
                            }
                        }
                    }
                    li:nth-child(1){
                        width: 100%;
                    }
                }
            }
        }
    }
</style>